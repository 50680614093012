/* NOTE: the order of these styles DO matter
 * This represents custom css for the react-dates component
 */

.DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #ccc;
}

.DateInput {
    position: static;
    width: auto;
    background: none;
}

.DateInput_input {
    position: static;
    width: 100px;
    font-size: 1em;
    text-align: center;
    margin: 6px 6px 4px;
    padding: 0px;
}