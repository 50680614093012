body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
 * -- MENU STYLES --
 * I want to customize how my .pure-menu looks at the top of the page
 */

 .home-menu {
  padding: 0.5em;
  /* text-align: justify; */
  box-shadow: 0 1px 1px rgba(0,0,0, 0.10);
}
.home-menu {
  background: rgba(24, 45, 58, 0.8);
}
.pure-menu.pure-menu-fixed {
  /* Fixed menus normally have a border at the bottom. */
  border-bottom: none;
  /* higher z-index for scroll-over effectnpm */
  z-index: 4;
}
.pure-menu-list {
  float: right;
}
.home-menu .pure-menu-heading {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  float: left;
}

.home-menu .pure-menu-selected a {
  color: white;
}

.home-menu a {
  color: rgb(132, 186, 223);
  border-bottom: 1px solid transparent;
  outline: none;
}
.home-menu li a:hover,
.home-menu li a:focus {
  background: none;
  border-bottom: 1px solid #fff;
  /* border: none; */
  color: #AECFE5;
}

/*
 * -- SPLASH STYLES --
 * This is the blue top section that appears on the page.
 */

 .splash-container {
  background-image: url(./images/camping1.jpg);
  background-size: cover;
  /* background: #1f8dd6; */
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 78%;
  position: absolute;
}

.splash {
  /* absolute center .splash within .splash-container */
  width: 80%;
  height: 80%;
  max-width: 1200px;
  margin: auto;
  position: absolute;
  top: 100px; left: 0; bottom: 0; right: 0;
  text-align: center;
}

.splash-head {
  font-size: 3em;
  font-weight: bold;
  color: rgb(24, 45, 58, 0.9);
  line-height: 1em;
  padding: 0.8em;
  margin: 0 auto;
  /* text-transform: uppercase; */
}

.splash-content {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 5px 1px rgba(0,0,0, 0.1);
  width: 100%;
  margin: auto;
  padding: 10px;
  text-align: center;
  border: 1px white;
  border-radius: 25px;
}

.splash-form {
  margin: 0 auto;
  padding: 0px 10px;
  max-width: 350px;
}

.splash-form-label {
  margin: 10px 0px;
  font-size: 0.9em;
  font-weight: 700;
  color: rgb(24, 45, 58);
  text-align: justify;
}

.splash-form-icon {
  vertical-align: -5%;
  margin-right: 5px;
  color: rgba(24, 45, 58, 0.6)
}

.splash-form-button {
  margin: 20px 0px 10px;
}

.button-submit.pure-button {
  border-radius: 4px;
  background-color: rgb(25, 96, 117);
  color: #fff;
  font-weight: 600;
  padding: 0.5em 2em;
  /* background-color: rgb(24, 45, 58) !important; */
}

/*
 * -- CONTENT STYLES --
 * This represents the content area (below the splash section)
 */
 .content-wrapper {
    width: 100%;
    top: 77%;
    position: absolute;
    background: white;
    z-index: 1;
}

/* This is the class used for the dark-background areas. */
.ribbon {
  background: rgb(24, 45, 58);
  color: #aaa;
}



/*
 * -- FORM STYLES --
 * This represents the form area (inside the splash section)
 */

 /* .prompt-form {
  margin: 10px;
 }

.prompt-area {
  width: 100%;
  text-align: center;
}

.prompt-input {
	background-color: transparent;
	font-size: 1.25em;
  margin: 0.25em !important;
  padding: 0.25em !important;
	min-width: 300px;
	border: 0px !important ;
  border-radius: 0px !important;
  box-shadow: none !important;
	outline: none;
}

input[type=text]:focus {
  outline: none;
  background-color: rgb(196, 212, 228);
}

.form-button {
  width: 100%;
}

.custom-prompt.pure-menu-list {
  width: 100%;
} */